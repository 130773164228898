import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Surface } from '../surface';

export const Container = styled(Surface)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    borderLeft: 'none',
    borderRight: 'none',
  },
}));

export const Content = styled('div')(() => ({
  maxHeight: '600px',
  overflowY: 'auto',
}));

export const HeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(6),
}));

export const SubHeadingWrapper = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 3, 10),
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body2.fontSize,
}));
