import { styled } from '@mui/material/styles';

import { OfferingTitleIcon } from './OfferingTitle.model';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  flex: '1 1 auto',
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(6),
  },
}));

export const Copy = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignContent: 'stretch',
  alignItems: 'stretch',
  gap: theme.spacing(2),
}));

export const Icon = styled('div')<OfferingTitleIcon>(({ theme, src, compact }) => ({
  borderRadius: theme.shape.radius(0.5),
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',

  width: theme.spacing(16),
  height: theme.spacing(16),
  ...(src && { backgroundImage: `url(${src})` }),
  ...(compact && {
    width: theme.spacing(12),
    height: theme.spacing(12),
  }),
}));
