import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { trim } from 'lodash';
import { TaxDocumentId, allToken, userApi, documentsApi } from '@yieldstreet/platform-kit';
import { PortfolioTaxResponse, portfolioTaxInitialState } from '../usePortfolioTax.model';

export const PortfolioTaxContext = React.createContext({
  ...portfolioTaxInitialState,
});

export const usePortfolioTaxContext = (): PortfolioTaxResponse => {
  const [taxDocumentIds, setTaxDocumentIds] = useState<TaxDocumentId[]>([]);

  const addOrRemoveTaxDocumentId = useCallback(
    (documentId: TaxDocumentId) => {
      setTaxDocumentIds(prevIds => {
        const index = prevIds.indexOf(documentId);

        if (index === -1) {
          return [...prevIds, documentId];
        } else {
          return [...prevIds.slice(0, index), ...prevIds.slice(index + 1)];
        }
      });
    },
    [setTaxDocumentIds]
  );

  const { data: user } = userApi.useGetUserStateQuery(allToken);

  const [downloadTaxDocuments, { isSuccess, data: documentsResponse, isLoading }] =
    documentsApi.useDownloadTaxDocumentsMutation();

  const handleDownload = useCallback((data, headers) => {
    const filename = headers['content-disposition']?.split('filename=')?.[1];
    const href = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', trim(filename, '"'));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, []);

  useEffect(() => {
    if (isSuccess && documentsResponse) {
      handleDownload(documentsResponse.file, documentsResponse.headers);
    }
  }, [isSuccess, documentsResponse, handleDownload]);

  const downloadTaxDocumentsRequest = useCallback(
    (documentId?: TaxDocumentId[]) => {
      const docIds = documentId ? documentId : taxDocumentIds;
      downloadTaxDocuments({
        userId: user?.id ?? 0,
        taxDocumentIds: docIds,
      });
    },
    [taxDocumentIds, user?.id, downloadTaxDocuments]
  );

  const clearTaxDocumentIds = useCallback(() => setTaxDocumentIds([]), [setTaxDocumentIds]);

  const isDocumentSelected = useCallback(
    (documentId: TaxDocumentId) => taxDocumentIds.includes(documentId),
    [taxDocumentIds]
  );

  const hasDocumentSelected = useMemo(() => taxDocumentIds.length > 0, [taxDocumentIds]);

  return {
    addOrRemoveTaxDocumentId,
    downloadTaxDocuments: downloadTaxDocumentsRequest,
    isDownloadingTaxDocuments: isLoading,
    clearTaxDocumentIds,
    isDocumentSelected,
    setTaxDocumentIds,
    taxDocumentIds,
    hasDocumentSelected,
  };
};
