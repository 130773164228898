import { TierLevels } from '@yieldstreet/platform-kit';

export interface TierBenefitsSection {
  [key: string]: string[];
}

export const enum TierBenefitsSectionIds {
  DedicatedClientGroup = 'dedicated-client-group',
  ExclusiveEvents = 'exclusive-events',
  InvestmentOffering = 'investment-offerings',
  PrioritySupport = 'priority-support',
}

const pcgRepTitle = 'Private Client Group relationship';
const dedicatedPCGRepTitle = 'Dedicated Private Client Group relationship';

const pcgRep = 'Dedicated Investor Relations access via phone, email and chat';
const priorityAccess =
  'Access to a designated Associate who delivers customized education and investment expertise';
const exclusiveEventsDefaults = [
  'Invitations to investor appreciation dinners and events',
  'Member-only access to webinars with industry experts',
];

export const clientGroupTitle: any = {
  [TierLevels.DIAMOND]: dedicatedPCGRepTitle,
  [TierLevels.PLATINUM]: dedicatedPCGRepTitle,
  [TierLevels.GOLD]: pcgRepTitle,
};

export const dedicatedClientGroupContent: TierBenefitsSection = {
  [TierLevels.DIAMOND]: [priorityAccess, 'Quarterly portfolio reviews'],
  [TierLevels.PLATINUM]: [priorityAccess, 'Biannual portfolio reviews'],
  [TierLevels.GOLD]: [priorityAccess, 'Annual portfolio review'],
};

export const exclusiveEventsContent: TierBenefitsSection = {
  [TierLevels.DIAMOND]: [
    ...exclusiveEventsDefaults,
    'VIP tickets to sports, arts, and entertainment events through our Diamond Days initiative',
  ],
  [TierLevels.PLATINUM]: exclusiveEventsDefaults,
  [TierLevels.GOLD]: exclusiveEventsDefaults,
};

export const investmentOfferingContent: TierBenefitsSection = {
  [TierLevels.DIAMOND]: [
    'Two Diamond-exclusive Short Term Notes per year at a premium rate',
    'Early access to six investments per year',
  ],
  [TierLevels.PLATINUM]: [
    'One Platinum-exclusive Short Term Note per year at a premium rate',
    'Early access to three investments per year',
  ],
  [TierLevels.GOLD]: ['Early access to one investment per year'],
};

export const prioritySupportContent: TierBenefitsSection = {
  [TierLevels.DIAMOND]: [pcgRep],
  [TierLevels.PLATINUM]: [pcgRep],
};
