import axios, { AxiosInstance } from 'axios';
import forEach from 'lodash/forEach';

import { restApi, Tags } from '../common';
import { OfferingApiReservedResponse, OfferingApiResponse } from './offering.model';

export const offeringApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.Offering, Tags.OfferingDetails],
  })
  .injectEndpoints({
    endpoints: builder => {
      const details = builder.query<
        Record<string, OfferingApiResponse | OfferingApiReservedResponse>,
        string[]
      >({
        queryFn: async (urlHashes, { extra }) => {
          const axiosInstance = (extra as AxiosInstance) || axios;
          return await Promise.all(
            urlHashes.map(urlHash =>
              axiosInstance.get(`/a/api/offering/${urlHash}`, {
                headers: { 'url-hash': urlHash },
              })
            )
          )
            .then(([...responses]) => {
              const data: Record<string, OfferingApiResponse | OfferingApiReservedResponse> = {};
              forEach(responses, x => {
                // NOTE: api does not return the urlHash when note is reserved. Note url is retrieved from headers.
                const urlHash = x.request?._headers?.['url-hash'] || x.config.headers?.['url-hash'];
                Object.assign(data, { [urlHash]: x.data });
              });

              return { data };
            })
            .catch(errors => {
              return { error: errors };
            });
        },
        providesTags: [Tags.OfferingDetails],
      });

      return {
        details,
      };
    },
  });
