import globalAxios, { AxiosInstance, Method, ResponseType } from 'axios';

type HttpMethod = Method;

export type HttpRequestParams = {
  url: string;
  method?: HttpMethod;
  data?: any;
  headers?: any;
  params?: any;
  paramsSerializer?: (params: any) => string;
  responseType?: ResponseType;
};

let axios: AxiosInstance = globalAxios;

export const setAxiosInstance = (axiosInstance: AxiosInstance) => (axios = axiosInstance);

export const httpRequest = (params: HttpRequestParams, axiosInstance: AxiosInstance = axios) => {
  return axiosInstance(params);
};
