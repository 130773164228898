export enum InvestorEntityTypeName {
  INDIVIDUAL = 'INDIVIDUAL',
  SINGLE_MEMBER_LLC = 'SINGLE_MEMBER_LLC',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  S_CORPORATION = 'S_CORPORATION',
  C_CORPORATION = 'C_CORPORATION',
  ESTATE = 'ESTATE',
  PARTNERSHIP = 'PARTNERSHIP',
  LLC = 'LLC',
  TRUST_EIN = 'TRUST_EIN',
  TRUST_SSN = 'TRUST_SSN',
  TRADITIONAL_IRA = 'TRADITIONAL_IRA',
  ROTH_IRA = 'ROTH_IRA',
  LEGACY_IRA = 'LEGACY_IRA',
}

export enum InvestorEntityTypeNameMap {
  INDIVIDUAL = 'Individual',
  SINGLE_MEMBER_LLC = 'Single member LLC',
  SOLE_PROPRIETOR = 'Sole Proprietor',
  S_CORPORATION = 'S Corporation',
  C_CORPORATION = 'C Corporation',
  ESTATE = 'ESTATE',
  PARTNERSHIP = 'Partnership',
  LLC = 'Limited Liability Company',
  TRUST_EIN = 'Trust',
  TRUST_SSN = 'Trust',
  TRADITIONAL_IRA = 'Traditional YSIRA',
  ROTH_IRA = 'Roth YSIRA',
  LEGACY_IRA = 'IRA',
}
