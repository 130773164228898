import type { BaseQueryFn } from '@reduxjs/toolkit/query';

import { BaseQueryConfig, BASE_CONFIG } from './base-config';
import { queryParser } from './query-parser';
import { ApiError } from './types';
import { httpRequest, HttpRequestParams } from './http-service';
import { Axios, AxiosInstance } from 'axios';

type RestQueryProps = Omit<HttpRequestParams, 'paramsSerializer'>;

export const baseRestQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<RestQueryProps, Axios, ApiError> =>
  async ({ url, ...params }: RestQueryProps, { extra }) => {
    const axiosInstance = extra as AxiosInstance;

    return await httpRequest(
      {
        ...params,
        url: baseUrl + url,
        paramsSerializer: (params: any) => queryParser(params),
      },
      axiosInstance
    )
      .then(result => ({ data: result.data, meta: result.headers }))
      .catch((error: ApiError) => ({ error }));
  };

export const BASE_REST_CONFIG: BaseQueryConfig = {
  ...BASE_CONFIG,
  baseQuery: baseRestQuery(),
};
