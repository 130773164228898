import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import { AccreditedStatus, AccreditedStatusDataResponse } from '../../types';

export const offeringEligibilityApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.OfferingEligibility],
  })
  .injectEndpoints({
    endpoints: builder => {
      const eligibility = builder.mutation<AccreditedStatus[], { offerings: string[] }>({
        query: ({ offerings }) => ({
          url: `/a/api/user/resolve-offering-eligibility`,
          method: HttpMethods.Post,
          data: { offerings },
        }),
        invalidatesTags: (_result, _error) => [{ type: Tags.OfferingEligibility }],
        transformResponse: (result: AccreditedStatusDataResponse) => result?.offerings || [],
      });

      return {
        eligibility,
      };
    },
  });
