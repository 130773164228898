import { alpha } from '@mui/material/styles';
import { ColorSystemOptions } from '@mui/material';

import { ACTIVE } from '../palette/active';
import { BRAND } from '../palette/brand';
import { CHARTS } from '../palette/charts';
import { NEUTRALS } from '../palette/neutrals';
import { SECONDARY } from '../palette/secondary';
import { SEMANTIC } from '../palette/semantic';

export const light: ColorSystemOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: BRAND.midnightGreen['500'],
      dark: BRAND.midnightGreen['700'],
      light: BRAND.midnightGreen['400'],
      contrastText: NEUTRALS.white['50'],
    },
    secondary: {
      main: ACTIVE.mint['500'],
      dark: ACTIVE.mint['700'],
      light: ACTIVE.mint['300'],
      contrastText: BRAND.midnightGreen['500'],
    },
    tertiary: {
      main: NEUTRALS.white['50'],
      dark: NEUTRALS.grey['100'],
      light: NEUTRALS.white['50'],
      contrastText: BRAND.parkGreen['800'],
    },
    info: {
      main: SEMANTIC.info['500'],
      dark: SEMANTIC.info['600'],
      light: SEMANTIC.info['300'],
      contrastText: NEUTRALS.black['900'],
    },
    error: {
      main: SEMANTIC.error['500'],
      dark: SEMANTIC.error['600'],
      light: SEMANTIC.error['300'],
      contrastText: NEUTRALS.black['900'],
    },
    success: {
      main: SEMANTIC.success['500'],
      dark: SEMANTIC.success['600'],
      light: SEMANTIC.success['300'],
      contrastText: NEUTRALS.black['900'],
    },
    warning: {
      main: SEMANTIC.warning['500'],
      dark: SEMANTIC.warning['600'],
      light: SEMANTIC.warning['500'],
      contrastText: NEUTRALS.black['900'],
    },
    chart: {
      1: CHARTS.chart1['500'],
      2: CHARTS.chart2['500'],
      3: CHARTS.chart3['500'],
      4: CHARTS.chart4['500'],
      5: CHARTS.chart5['500'],
      6: CHARTS.chart6['500'],
      7: CHARTS.chart7['500'],
      8: CHARTS.chart8['500'],
      9: CHARTS.chart9['500'],
      10: CHARTS.chart10['500'],
    },
    FilledInput: {
      bg: NEUTRALS.white['50'],
      hoverBg: NEUTRALS.white['200'],
    },
    Button: {
      contained: {
        primary: {
          background: ACTIVE.mint['500'],
          color: BRAND.parkGreen['900'],
          colorInverse: NEUTRALS.white[50],
          hover: ACTIVE.mint['600'],
          active: ACTIVE.mint['600'],
        },
        secondary: {
          background: BRAND.midnightGreen['500'],
          color: NEUTRALS.white['200'],
          hover: BRAND.midnightGreen['400'],
        },
        marketing: {
          background: ACTIVE.chartreuse['500'],
          color: BRAND.midnightGreen['500'],
          hover: ACTIVE.chartreuse['600'],
        },
      },
      outlined: {
        tertiary: {
          background: NEUTRALS.white['50'],
          border: NEUTRALS.grey['100'],
          color: BRAND.parkGreen['800'],
          hover: NEUTRALS.grey['100'],
        },
      },
    },
    Alert: {
      standard: {
        warning: {
          background: SEMANTIC.warning['100'],
          color: SEMANTIC.warning['900'],
        },
        error: {
          background: SEMANTIC.error['100'],
          color: SEMANTIC.error['900'],
        },
        info: {
          background: SEMANTIC.info['100'],
          color: SEMANTIC.info['900'],
        },
        success: {
          background: SEMANTIC.success['100'],
          color: SEMANTIC.success['900'],
        },
      },
      filled: {
        success: {
          color: SEMANTIC.success['900'],
        },
      },
    },
    OnboardButton: {
      color: BRAND.midnightGreen['500'],
      colorInverse: NEUTRALS.white['50'],
      background: NEUTRALS.white['50'],
      active: BRAND.midnightGreen['500'],
      hover: alpha(BRAND.parkGreen['500'], 0.08),
    },
    NavigationHeader: {
      background: {
        // THIS IS TEMPORARY until we get updated design colors
        default: '#1A1B1D',
        inverse: NEUTRALS.white['50'],
      },
      button: {
        secondary: {
          default: NEUTRALS.grey['500'],
          hover: NEUTRALS.grey['400'],
          active: NEUTRALS.grey['600'],
        },
      },
    },
    NavigationFooter: {
      background: {
        default: BRAND.parkGreen['700'],
      },
    },
    common: {
      onBackgroundChannel: NEUTRALS.black['900'],
      white: NEUTRALS.white['50'],
      black: NEUTRALS.black['900'],
    },
    background: {
      card: {
        default: SECONDARY.sand['100'],
      },
      dark: BRAND.midnightGreen['900'],
      darkGreen: BRAND.parkGreen['700'],
      default: NEUTRALS.white['50'],
      highlight: BRAND.parkGreen['300'],
      inverse: BRAND.midnightGreen['500'],
      page: {
        marketing: NEUTRALS.white['50'],
        product: '#F1F3F6', // FIX ME - replace when design team decide on color
      },
      surface: NEUTRALS.grey['50'],
    },
    text: {
      disabled: NEUTRALS.grey['400'],

      //paragraph Primary
      primary: NEUTRALS.black['900'],

      //paragraph Secondary
      secondary: NEUTRALS.grey['700'],

      tertiary: NEUTRALS.grey['500'],

      // LEGACY these need to be migrated
      // Label Primary inverse / Label Secondary
      quaternary: NEUTRALS.grey['300'],
      // Paragraph secondary inverse
      quinary: NEUTRALS.grey['100'],
      // Header inverse
      senary: NEUTRALS.white['300'],

      // DEPRECATED PLEASE DO NOT ADD HERE
      header: {
        default: BRAND.midnightGreen['500'],
        inverse: NEUTRALS.white['300'],
      },
      paragraphPrimary: {
        default: NEUTRALS.black['900'],
        inverse: NEUTRALS.white['50'],
      },
      paragraphSecondary: {
        default: NEUTRALS.grey['800'],
        inverse: NEUTRALS.grey['100'],
      },
      labelPrimary: {
        default: NEUTRALS.grey['800'],
        inverse: NEUTRALS.grey['300'],
      },
      labelSecondary: NEUTRALS.grey['300'],
    },
    border: {
      inverse: NEUTRALS.white['50'],
      default: NEUTRALS.grey['100'],
      strong: NEUTRALS.grey['300'],
      strong_alt: NEUTRALS.grey['400'],
      extra_strong: NEUTRALS.grey['500'],
      outline: BRAND.midnightGreen['500'],
    },
    accent: {
      strong: ACTIVE.mint['500'],
      medium: ACTIVE.mint['400'],
      light: ACTIVE.mint['300'],
    },
    brand: {
      primary: {
        main: ACTIVE.chartreuse['500'],
        dark: ACTIVE.chartreuse['600'],
      },
      tertiary: {
        main: BRAND.patinaGreen['400'],
      },
      secondary: {
        light: SECONDARY.sand['100'],
        complementary: SECONDARY.sand['300'],
        main: SECONDARY.sand['400'],
        contrast: SECONDARY.sand['900'],
      },
      text: {
        misc1: NEUTRALS.grey['50'],
      },
    },
  },
};
