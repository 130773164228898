import { styled } from '@mui/material/styles';

import { ValidationIndicatorIconProps } from './ValidationIndicator.model';

export const Indicator = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;

export const Icon = styled('div')<ValidationIndicatorIconProps>(({ icon, color, theme }) => ({
  position: 'relative',
  flex: ' 0 0 16px',
  width: '16px',
  height: '16px',
  aspectRatio: '1 / 1',
  borderRadius: ' 50%',
  transition: '0.5s ease-in-out',
  border: `1px solid ${color}`,
  backgroundColor: color,
  boxShadow: `inset 0 0 0 1px ${theme.palette.common.white}`,
  '&::before': {
    content: "''",
    position: 'absolute',
    width: '16px',
    height: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    mask: `url(${icon}) no-repeat center`,
    maskSize: '75% 75%',
  },
}));
