import axios, { AxiosInstance } from 'axios';
import forEach from 'lodash/forEach';

import { restApi, Tags } from '../../common';
import { HttpMethods, PortfolioFilters } from '../../../enums';

import {
  PortfolioOverviewRequest,
  PortfolioOverviewResponse,
  AllPortfolioOverviewRequest,
  AllPortfolioOverviewResponse,
} from './overview.model';

export const overviewApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioOverview],
  })
  .injectEndpoints({
    endpoints: build => {
      const getPortfolioOverview = build.query<PortfolioOverviewResponse, PortfolioOverviewRequest>(
        {
          query: ({ userId, ...params }: PortfolioOverviewRequest) => ({
            url: `/api/portfolio/overview/${userId}`,
            method: HttpMethods.Get,
            params,
          }),
          providesTags: [Tags.PortfolioOverview],
        }
      );

      const getAllPortfolioOverview = build.query<
        Record<
          string,
          | PortfolioOverviewResponse
          | PortfolioOverviewRequest
          | AllPortfolioOverviewRequest
          | AllPortfolioOverviewResponse
        >,
        AllPortfolioOverviewRequest
      >({
        queryFn: async (
          {
            end,
            includePrincipalWriteOff,
            includeRepaidCapital,
            includeRepaidPrincipal,
            investorAccountIds,
            noteUrlHash,
            offeringData,
            start,
            userId,
            managementStrategy,
          },
          { extra }
        ) => {
          const axiosInstance = (extra as AxiosInstance) || axios;
          return await Promise.all(
            investorAccountIds?.map(investorAccountId =>
              axiosInstance.get(`/api/portfolio/overview/${userId}`, {
                headers: { 'investor-account-id': investorAccountId },
                params: {
                  investorAccountId,
                  end,
                  includePrincipalWriteOff,
                  includeRepaidCapital,
                  includeRepaidPrincipal,
                  noteUrlHash,
                  offeringData,
                  start,
                  userId,
                  managementStrategy,
                },
              })
            )
          )
            .then(([...responses]) => {
              const data: Record<string, PortfolioOverviewResponse | PortfolioOverviewRequest> = {};

              forEach(responses, x => {
                const investorAccountId =
                  x.request?._headers?.['investor-account-id'] ||
                  x.config.headers?.['investor-account-id'] ||
                  PortfolioFilters.ALL;

                Object.assign(data, { [investorAccountId]: x.data });
              });

              return { data };
            })
            .catch(errors => {
              return { error: errors };
            });
        },
        providesTags: [Tags.PortfolioOverview],
      });

      return {
        getPortfolioOverview,
        getAllPortfolioOverview,
      };
    },
  });
