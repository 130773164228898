import axios, { AxiosInstance } from 'axios';
import forEach from 'lodash/forEach';

import { restApi, Tags } from '../common';
import {
  InvestorAccountNoteConfigApiResponse,
  InvestorAccountNoteConfigRequest,
  InvestorAccountApiResponse,
  CreateProviderInvestorAccountApiRequest,
} from './investor-account.model';
import { HttpMethods } from '../../enums/http';

export const investorAccountsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.BankAccount,
      Tags.InvestorAccount,
      Tags.InvestorAccountNoteConfig,
      Tags.DistributionReinvestment,
      Tags.InvestorAccountWallet,
      Tags.AdviceManagedPortfolio,
      Tags.PortfolioTransactionPayments,
      Tags.PortfolioInvestmentsByStatus,
    ],
  })
  .injectEndpoints({
    endpoints: builder => {
      const createProviderInvestorAccount = builder.mutation<
        InvestorAccountApiResponse,
        CreateProviderInvestorAccountApiRequest
      >({
        query: ({ provider }) => ({
          url: `/a/api/investor-account/provider/${provider}`,
          method: HttpMethods.Post,
        }),
        invalidatesTags: [Tags.InvestorAccount],
      });

      const getInvestorAccountNoteConfig = builder.query<
        InvestorAccountNoteConfigApiResponse,
        InvestorAccountNoteConfigRequest
      >({
        query: params => ({
          url: `/a/api/investor/account/note/config`,
          method: HttpMethods.Get,
          body: params,
        }),
        providesTags: [Tags.InvestorAccountNoteConfig],
      });

      const getDistributionReinvestment = builder.query<any, any>({
        queryFn: async ({ investorAccountId, urlHashes }, { extra }) => {
          const axiosInstance = (extra as AxiosInstance) || axios;

          const apis: any = [];
          forEach(urlHashes, urlHash => {
            apis.push(
              axiosInstance.get(
                `/a/api/investor/account/${investorAccountId}/note/${urlHash}/config`,
                {
                  params: { urlHash },
                }
              )
            );
          });

          return await axios
            .all(apis)
            .then(
              axios.spread((...responses: any[]) => {
                const data = {};
                forEach(responses, x => {
                  Object.assign(data, { [x.config?.params?.urlHash]: x.data });
                });
                return { data };
              })
            )
            .catch(errors => {
              return { data: {}, error: errors };
            });
        },
        providesTags: [Tags.DistributionReinvestment],
      });

      return {
        createProviderInvestorAccount,
        getInvestorAccountNoteConfig,
        getDistributionReinvestment,
      };
    },
  });
